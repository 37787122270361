/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */

/* clearfix */
.owl-carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
/* display none until init */
.owl-carousel {
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper {
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer {
	overflow: hidden;
	position: relative;
	width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}
.owl-carousel .owl-item {
	float: left;
}
.owl-controls .owl-page, .owl-controls .owl-buttons div {
	cursor: pointer;
}
.owl-controls .owl-buttons div {
	position: absolute;
}
.owl-controls .owl-buttons .owl-prev {
	left: 10px;
	top: 50%;
}
.owl-controls .owl-buttons .owl-next {
	right: 10px;
	top: 50%;
}
.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* mouse grab icon */
.grabbing {
	cursor: url(grabbing.png) 8 8, move;
}
/* fix */
.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
}
/* OpenCart Code */
.owl-carousel {
	margin: 0 0 20px;
	background: #fff;
	direction: ltr !important;
	z-index: 1;
}
.owl-wrapper-outer {
	border: 4px solid #fff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: 0 1px 4px rgba(0,0,0,.2);
	-moz-box-shadow: 0 1px 4px rgba(0,0,0,.2);
	-o-box-shadow: 0 1px 4px rgba(0,0,0,.2);
	box-shadow: 0 1px 4px rgba(0,0,0,.2);
}
.owl-carousel .owl-buttons div {
	opacity: 0;
	color: rgba(0,0,0,0.8);
	text-shadow: 1px 1px 0 rgba(255,255,255,0.3);
	transition: all .3s ease;
}
.owl-carousel:hover .owl-buttons div {
	opacity: 0.7;
}
.owl-carousel .owl-buttons .owl-prev {
	left: 10px;
}
.owl-carousel .owl-buttons .owl-next {
	right: 10px;
}
.owl-carousel .owl-buttons div i {
	font-size: 40px;
	margin: -20px 0 0;
}
.owl-pagination {
	text-align: center;
	top: 20px;
	position: relative;
}
.owl-controls .owl-page {
	display: inline-block;
	margin: 0px 6px;
}
.owl-controls .owl-page span {
	display: block;
	width: 11px;
	height: 11px;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
}
.owl-controls .owl-page.active span {
	background: rgba(0, 0, 0, 0.9);
}
