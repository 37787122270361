@import "vars";

@media only screen and (max-width : 1200px) {
	.header {
		&-profile, &-links {} }

	.product-thumb .buttons button + button {
		margin-left: 3px; }
	footer .contact-info b {
		display: block; } }


@media only screen and (max-width : 992px) {
	.product-thumb .buttons button + button {
		margin-left: 10px; }
	.alert {
		padding-right: 40px;
		.close {
			position: absolute;
			font-size: 35px;
			top: 50%;
			right: 12px;
			transform: translateY(-50%); } } }


@media only screen and (max-width : 768px) {
	.header {
		padding-bottom: 11px;
		&:after, &-links {
			display: none; }
		&-profile {
			order: 2;
			width: 50%;
			padding-left: 20px;
			padding-top: 10px;
			li {
				display: block;
				a {
					color: inherit;
					line-height: 18px; } } }
		&-phone, &-regim {
			font-weight: normal;
			font-size: 14px;
			padding-top: 7px;
			.fa {
				font-size: 18px;
				float: left;
				padding-top: 14px; }
			div, ul {
				margin-left: 18px; } }
		&-phone {
			order: 3; }
		&-regim {
			order: 4; }
		&-callback {
			padding-top: 7px;
			order: 5;
			.btn {
				padding: 3px 10px;
				font-size: 13px; } }
		&-cart {
			padding-top: 7px;
			order: 6;
			.fa {
				font-size: 18px; }
			.cart-total {
				font-size: 13px;
				padding: 2px 5px;
				span {
					display: none; } } } }

	.product-thumb {
		.image {
			margin-bottom: 10px; }
		.name {
			overflow: visible;
			height: auto; }
		.price {
			line-height: 1; }
		.model, .stock {
			padding: 0; }
		.model {
			margin-bottom: 0; }
		.stock {
			margin-bottom: 10px; } }

	.main-menu {
		border-bottom: none;
		padding: 5px 0;

		.container {
			.mobile-menu-toggle {
				padding: 0;
				margin: 0;
				background: none;
				border: none;
				.fa {
					color: #ffffff;
					font-size: 20px; } }
			h4 {
				color: #fff;
				padding: 0;
				margin: 0;
				font-weight: normal;
				font-size: 15px;
				line-height: 24px; }
			ul {
				display: none;
				li {
					display: block;
					text-align: left;
					a {
						display: block;
						font-size: 14px;
						padding: 0; } } }
			.visible-xs {
				text-align: center;
				.mobile-menu-toggle {
					float: left; }
				.logo-mobile {
					margin-left: 15px;
					display: inline-block;
					img {
						max-width: 100%;
						height: 25px; } } } } }
	.compare-info {
		.buttons {
			a {
				margin-top: 10px; } } } }


// @media only screen and (max-width : 480px)



// @media only screen and (max-width : 320px)




