@import "fonts";
@import "libs";
@import "vars";

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666; }
body input:required:valid,
body textarea:required:valid {
	color: #666; }

html, body {
	height: 100%; }

body {
	font-size: 15px;
	min-width: 320px;
	position: relative;
	line-height: 1.75;
	font-weight: 300;
	font-family: $default-font;
	overflow-x: hidden;
	opacity: 1;
	background-color: #EBEBEB; }

h1, h2, h3, h4, h5, h6 {
	color: #000;
	font-weight: 400; }

h1 {
	margin-top: 0; }

h3 {
	margin-bottom: 20px; }

label {
	font-weight: 400;
	color: #000; }

button,
a {
	outline: none; }

a {
	color: #016e84;
	outline: none;
	&:hover {
		text-decoration: none; } }
.input-group .form-control {
	z-index: inherit; }
.uppercase {
	text-transform: uppercase; }

.dropdown-menu li > a:hover {
	text-decoration: none;
	color: #ffffff;
	background-color: #229ac8;
	background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
	background-repeat: repeat-x; }

.sticky-wrapper {
	position: relative;
	z-index: 2; }

.header {
	position: relative;
	z-index: 0;
	background-color: #fff;
	padding-bottom: 15px;
	> * {
		position: relative;
		z-index: 1; }
	&:after {
		content: '';
		display: block;
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		right: 0;
		height: 30px;
		background-color: #016e84; }
	&-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center; }
	ul {
		margin: 0;
		padding: 0;
		list-style: none; }
	&-profile, &-links {
		width: 50%;
		font-size: 14px; }
	&-profile {
		text-align: right;
		li {
			display: inline-block;
			margin-right: 10px;
			a {
				color: #fff;
				line-height: 30px;
				text-decoration: none;
				.fa {
					margin-right: 5px; }
				&:hover {
					color: #fafafa;
					text-decoration: underline; } } } }
	&-links {
		li {
			display: inline-block;
			margin-left: 10px;
			a {
				color: #fff;
				line-height: 30px;
				text-decoration: none;
				&:hover {
					color: #fafafa;
					text-decoration: underline; } } } }
	&-phone, &-regim {
		text-align: center;
		padding-top: 15px;
		line-height: 22px;
		font-weight: 500;
		.fa {
			font-size: 22px; } }
	&-phone {
		a {
			color: #333; } }
	&-logo, &-callback, &-cart {
		padding-top: 15px; }
	&-cart {
		text-align: right;
		&-content {
			display: inline-block;
			a {
				color: #333;
				display: inline-block; } }
		.fa {
			font-size: 25px;
			display: inline-block;
			*display: inline;
			*zoom: 1;
			vertical-align: middle;
			color: #333; } } }

.cart-total {
	background-color: #333;
	color: #fafafa;
	padding: 3px 7px;
	margin-left: 5px;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	vertical-align: middle;
	position: relative;
	font-size: 14px;
	&:before {
		content: '';
		position: absolute;
		left: -10px;
		top: 50%;
		transform: translateY(-50%);
		border-right: 5px solid #333;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		border-left: 5px solid transparent; } }

.phone-in-sticky {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
	margin-bottom: 7px;
	a {
		color: #333;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		.fa {
			margin-right: 3px; } } }

.main-menu {
	background-color: #5A5A5C;
	border-bottom: 10px #29292a solid;
	ul {
		display: table;
		margin: 0;
		padding: 0;
		list-style-type: none;
		width: 100%;
		li {
			display: table-cell;
			text-align: center;
			a {
				color: #fff;
				display: block;
				font-size: 16px;
				padding: 3px 0;
				&:hover {
					color: #fee7a2;
					background-color: #434445; } } } } }
iframe {
	max-width: 100% !important; }

#content {
	min-height: 600px; }

footer {
	margin-top: 30px;
	border-top: 1px solid #747577;
	a {
		color: #333;
		&:hover {
			color: #fafafa;
			text-decoration: underline; } }
	h4 {
		color: #000; } }

.footer-top {
	padding: 20px 0;
	background-color: #818283;
	color: #333; }

.footer-bottom {
	padding: 20px 0;
	.powered {
		margin-top: 10px; } }

.alert {
	padding: 8px 14px 8px 14px; }

.breadcrumb {
	margin-bottom: 20px;
	background: transparent;
	a {
		font-weight: 300;
		&:hover {
			color: #000;
			text-decoration: underline; } } }

.pagination {
	margin-bottom: 20px; }

.btn {
	padding-left: 22px;
	padding-right: 22px; }

.btn-default {
	color: #016e84;
	border-radius: 20px;
	box-shadow: 0 0 0 5px #F3F4F7;
	background-image: linear-gradient(to top, #E5E6E7, #F9FBFC); }

.btn-primary {
	color: #3f4043;
	border-radius: 20px;
	border-color: #CC962F;
	text-transform: uppercase;
	box-shadow: 0 0 0 5px #F3F4F7;
	background-image: linear-gradient(to bottom, #CA9C46, #9C7324);
	&:hover, &:active, &.active, &.disabled, &[disabled] {
		background-image: linear-gradient(to top, #CA9C46, #9C7324);
		border-color: #CC962F;
		color: #cecece; } }

.btn-warning {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #faa732;
	background-image: linear-gradient(to bottom, #fbb450, #f89406);
	background-repeat: repeat-x;
	border-color: #f89406 #f89406 #ad6704;
	&:hover, &:active, &.active, &.disabled, &[disabled] {
		box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1); } }

.btn-danger {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #da4f49;
	background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
	background-repeat: repeat-x;
	border-color: #bd362f #bd362f #802420;
	&:hover, &:active, &.active, &.disabled, &[disabled] {
		box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1); } }

.btn-success {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #5bb75b;
	background-image: linear-gradient(to bottom, #62c462, #51a351);
	background-repeat: repeat-x;
	border-color: #51a351 #51a351 #387038;
	&:hover, &:active, &.active, &.disabled, &[disabled] {
		box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1); } }

.btn-info {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #df5c39;
	background-image: linear-gradient(to bottom, #e06342, #dc512c);
	background-repeat: repeat-x;
	border-color: #dc512c #dc512c #a2371a;
	&:hover, &:active, &.active, &.disabled, &[disabled] {
		background-image: none;
		background-color: #df5c39; } }


.carousel-caption {
	color: #FFFFFF;
	text-shadow: 0 1px 0 #000000; }

.carousel-control {
	.icon-prev:before {
		content: '\f053';
		font-family: FontAwesome; }
	.icon-next:before {
		content: '\f054';
		font-family: FontAwesome; } }

.product-thumb {
	border: 1px solid #fafafa;
	margin-bottom: 20px;
	padding-bottom: 15px;
	color: #000;
	box-shadow: 1px 1px 2px 2px #cecece;
	transition: all .5s;
	&:hover {
		box-shadow: none; }
	> * {
		padding: 0 10px; }
	.image {
		text-align: center;
		background-color: #fff;
		padding: 10px;
		margin-bottom: 15px;
		img {
			max-width: 100%; } }
	.name {
		margin-bottom: 3px;
		height: 75px;
		overflow: hidden;
		a {
			font-weight: 300;
			text-transform: uppercase;
			color: #00788a;
			line-height: 1.2;
			display: inline-block;
			&:hover {
				text-decoration: underline;
				color: #00788a; } } }
	.price {
		margin-bottom: 7px;
		.price-new {
			font-size: 1.3em;
			font-weight: 600;
			color: #000; }
		.price-old {
			text-decoration: line-through; } }
	.model {
		margin-bottom: 5px;
		font-size: .9em; }
	.stock {
		margin-bottom: 20px; }
	.buttons button + button {
		margin-left: 10px; } }

.thumbnail {
	background: transparent;
	border: none; }

a.thumbnail:hover {
	border-color: #fff; }

.main-image {
	padding: 10px 20px; }

.thumbnails {
	text-align: center;
	list-style: none;
	padding: 0;
	margin: 0;
	> {
		li {
			margin: 0 5px; }
		img {
			width: 100%; } } }

.image-additional {
	a {
		margin-bottom: 20px;
		padding: 5px;
		display: block;
		border: 1px solid #ddd; }
	max-width: 78px; }

.thumbnails .image-additional {
	display: inline-block; }


#column-left .product-layout, #column-right .product-layout {
	width: 100%; }


.mfp-ajax-holder .mfp-content {
	max-width: 400px;
	width: 100%; }

.callback-form {
	padding: 15px;
	margin-top: 35px;
	background-color: #fff; }

.mfp-ajax-holder .mfp-close {
	color: white;
	right: -6px;
	text-align: right;
	padding-right: 6px;
	width: 100%; }

.scroll-to-top {
	position: fixed;
	bottom: 15px;
	right: 15px;
	width: 40px;
	height: 40px;
	color: #ffffff;
	font-size: 13px;
	text-transform: uppercase;
	line-height: 38px;
	text-align: center;
	z-index: 100;
	cursor: pointer;
	border-radius: 50%;
	background: #1c1c1c;
	display: none;
	opacity: .6;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
	&:hover {
		opacity: 1; } }

.contact-page-top {
	margin-bottom: 20px; }

.contact-page-block {
	position: relative;
	margin-bottom: 15px;
	padding-left: 40px;
	.icon-block {
		position: absolute;
		top: 0;
		left: 0;
		background-color: #fff;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		border-radius: 50%; } }

.contact-page-middle img {
	max-width: 100%; }

.owl-carousel.slideshow {
	margin-top: 20px;
	margin-bottom: 30px;
	background: transparent; }

.slideshow .owl-wrapper-outer {
	border: none;
	box-shadow: none;
	background-color: #fff; }

.owl-carousel {
	background: none;
	.owl-wrapper-outer {
		background: none;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-align: center; }
	.owl-item {
		text-align: center; }
	.owl-buttons {
		width: 100%;
		top: 50%;
		.owl-prev, .owl-next {
			opacity: 1;
			.fa {
				font-size: 30px; }
			&.disabled {
				opacity: .3;
				cursor: default; } }
		.owl-prev {
			left: 0; }
		.owl-next {
			right: 0; } }
	.product-layout {
		display: inline-block;
		width: 250px;
		margin: 4px; }
	&.products-carousel-full-width, {
		padding: 0 15px; }
	&.product-info-mobile-images {
		padding-left: 20px;
		padding-right: 20px; } }

.product-info {
	h1 {
		margin-top: 0; }
	.quantity {
		margin-bottom: 15px;
		margin-top: 40px;
		> * {
			display: inline-block;
			*display: inline;
			*zoom: 1;
			vertical-align: middle;
			width: auto; } }
	.buttons {
		margin-bottom: 20px;
		button + button {
			margin-left: 15px; } }
	.minimum {
		margin-bottom: 20px;
		font-size: .8em; } }

.alert {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 300px;
	max-width: 600px;
	z-index: 999;
	background-color: #fafafa;
	.close {
		margin-left: 15px; } }

.alert-success {
	color: #000;
	border-color: #016e84; }

legend {
	font-size: 20px; }

.form-group.required {
	label {
		&:after {
			content: '*';
			color: #ff0000; } } }

.mfp-ajax-holder .mfp-content {
	max-width: 600px;
	width: 100%;
	background-color: #fff;
	.mfp-close {
		color: #333;
		right: 0;
		top: -6px; } }
.wrapper-popup-form {
	> div {
		padding: 15px;
		text-align: center; }
	.buttons {
		margin-top: 15px;
		a {
			margin-left: 10px; } }
	.compare-info {
		padding: 25px 15px 15px 15px;
		.alert {
			position: static; } } }

.compare-widget {
	a {
		position: fixed;
		text-decoration: none;
		right: 0;
		top: 25%;
		background-color: #fff;
		opacity: .6;
		box-shadow: 0 1px 3px #cecece;
		border: 1px maroon solid;
		border-right: none;
		padding: 5px 7px;
		z-index: 1;
		line-height: 1.3;
		.fa, span {
			display: block;
			text-align: center; }
		.fa {
			margin-bottom: 7px;
			font-size: 20px; }
		&:hover {
			opacity: 1; } } }
div.table-responsive {
	overflow-x: auto; }

.input-group-btn {
	z-index: 0; }

.text-checkout {
	position: static;
	transform: none;
	margin-bottom: 20px;
	max-width: 100%; }

.simplecheckout-cart-total.simplecheckout-cart-discounts {
	text-align: center;
	background-color: darken(#fafafa, 10%);
	.fa {
		color: $accent; } }

@import "media"; // Всегда в конце
